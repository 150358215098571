import React from 'react';
import Script from 'next/script';
import * as Sentry from '@sentry/react';
import getConfig from 'next/config';

import('../scss/index.scss');

Sentry.init({
  dsn: getConfig()?.publicRuntimeConfig?.sentryDSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function LendProApp({ Component, pageProps }) {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Component {...pageProps} />
      <Script
        id="global-script"
        strategy="afterInteractive"
        src="/js/document-helper.js"
      />
    </Sentry.ErrorBoundary>
  );
}
